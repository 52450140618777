import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavbarHome from '../../../components/NavbarHome';
import SubFooter from '../../../components/SubFooter';

import { getBusinessInfo } from '../../../apiService/capabilitiesPage';
import { Helmet } from 'react-helmet-async';

const PracticeArea = () => {
    const location = useLocation();
    const token = useSelector(state => state.token)
    const language = useSelector(state => state.language)

    const [data, setData] = useState({});

    useEffect(() => {
        // getBusinessInfo(location.state, token, language)
        getBusinessInfo(location.pathname, token, language)
            .then(res => {
                setData(res);
            })
            .catch(err => { })
    }, [language])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='bg-light dark:bg-dark tablet:pt-[10vh] pb-[15vh] pl-[5vw]'>
                <div className='flex flex-row justify-between pt-[5vh] w-[90vw]'>
                    <div className='flex flex-col'>
                        <h1 className='caption-home1 text-[#4493f1]'>{data?.title}</h1>
                        {data && data?.texts?.map((item, index, arr) => item.includes('**')
                            ? <p key={index} className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify  font-bold tablet:mb-[3vh] mobile:mb-[2vh]'>{item.replaceAll('**', '')}</p>
                            : item.startsWith('-')
                                ? <ul className='ml-[2vw] tablet:ml-[1vw]'>
                                    <li
                                        key={index}
                                        type="disc"
                                        className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify tablet:mb-[1vh] mobile:mb-[1vh]'
                                    >{item.replace('-', '')}</li>
                                </ul>
                                : <p key={index} className={`font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify tablet:mb-[3vh] mobile:mb-[2vh] ${index - 1 >= 0 && arr[index - 1].startsWith('-') && 'tablet:mt-[3vh] mobile:mt-[2vh]'}`}>{item}</p>
                        )}
                    </div>
                </div>

                {data?.partners?.length && <h1 className='caption-home1 dark:text-white text-[#123872]'>KEY CONTACTS</h1>}
                <div className='flex flex-row flex-wrap'>
                    {data && data?.partners?.map((item, index) => (
                        <div key={index} className='mr-[4vw] tablet:w-[20vw] mobile:w-[25vw]'>
                            <img src={`${process.env.REACT_APP_BASE_URL}${item.attributes?.avatar?.data?.attributes?.formats?.small?.url}`} alt='Key Contacts' className='tablet:w-[20vw] mobile:w-[25vw] py-[2vh]' />
                            <a href={`/people/${item.attributes?.full_name}`}><p className='description1 dark:text-white hover:underline mb-[1vh]'>{item.attributes?.full_name}</p></a>
                            <p className='font-mulish  dark:text-white tablet:text-[18px] mobile:text-[12px] tablet:leading-[5vh] mobile:leading-[3vh] '>{item.attributes?.type}</p>
                        </div>
                    ))}
                </div>
            </section>
            <SubFooter />
        </>

    );
}

export default PracticeArea;