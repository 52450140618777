import React, { useEffect, useState } from 'react';
import NavbarHome from '../../../components/NavbarHome';
import Mailto from '../../../components/Mailto';
import { qualifiedLawyersForm } from '../../../apiService/careerPage';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

const QualifiedLawyersForm = () => {
    const language = useSelector(state => state.language)

    const [isLoading, setIsLoading] = useState(false)

    const [first_name, setfirst_name] = useState('')
    const [last_name, setlast_name] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [domicile, setDomicile] = useState('')
    const [date_of_experience, setdate_of_experience] = useState('')
    const [employer_name, setemployer_name] = useState('')
    const [date_period, setdate_period] = useState('')
    const [location, setLocation] = useState('')
    const [position, setPosition] = useState('')
    const [description, setDescription] = useState('')

    const handleSubmitForm = () => {
        setIsLoading(true)
        qualifiedLawyersForm({
            first_name,
            last_name,
            phone,
            domicile,
            email,
            date_of_experience,
            employer_name,
            date_period,
            location,
            position,
            description
        })
            .then(res => {
                setIsLoading(false)
                alert('Form Sent Succesfully');
            })
            .catch(err => {
                setIsLoading(false)
                alert(err)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='items-start bg-white dark:bg-dark pl-[5vw] pt-[15vh]'>
                <div className='flex flex-row justify-center'>
                </div>
                <div className=''>
                    <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium pb-[3vh]'>
                        {language === 'en' ? 'associate APPLICATION' : 'LAMARAN KERJA TINGKAT ASSOCIATE'}
                    </h2>
                </div>

                <section className="pt-[2vh] pb-[5vw] flex items-center w-[95%]">
                    <form className='flex flex-col flex-wrap justify-between w-[100%]'>
                        <div>
                            <input type="file" />
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'first name *' : 'NAMA DEPAN *'}
                                </p>
                                <input onChange={e => setfirst_name(e.target.value)} value={first_name} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'last name *' : 'NAMA KELUARGA *'}
                                </p>
                                <input onChange={e => setlast_name(e.target.value)} value={last_name} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'no telephone/whatsapp *' : 'NO. TELEPON/WHATSAPP *'}
                                </p>
                                <input onChange={e => setPhone(e.target.value)} value={phone} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flexz flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'email address *' : 'ALAMAT EMAIL *'}
                                </p>
                                <input onChange={e => setEmail(e.target.value)} value={email} type="email" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                            </div>
                        </div>
                        <div className='flex flex-col font-medium mr-[1.5vw]'>
                            <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                {language === 'en' ? 'domicile *' : 'DOMISILI *'}
                            </p>
                            <input onChange={e => setDomicile(e.target.value)} value={domicile} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                        </div>
                        <div className='flex flex-col font-medium mr-[1.5vw]'>
                            <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                {language === 'en' ? 'YEARS OF EXPERIENCE *' : 'JUMLAH TAHUN PENGALAMAN KERJA *'}
                            </p>
                            <input onChange={e => setdate_of_experience(e.target.value)} value={date_of_experience} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                        </div>

                        <div className='mt-[8vh]'>
                            <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium pb-[3vh]'>
                                {language === 'en' ? 'EMPlOYMENT HISTORY' : 'RIWAYAT KARIR'}
                            </h2>
                            <h2 className='text-black font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] dark:text-white font-normal tracking-wide text-justify uppercase'>
                                {language === 'en' ? 'PROVIDE YOUR MOST RECENT FORMAL EDUCATION' : 'MOHON MENYEDIAKAN INFORMASI TERKAIT JABATAN DAN PEKERJAAN TERAKHIR ANDA'}
                            </h2>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'employer name *' : 'NAMA PERUSAHAAN/PEMBERI KERJA *'}
                                </p>
                                <input onChange={e => setemployer_name(e.target.value)} value={employer_name} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'date/periode *' : 'PERIODE *'}
                                </p>
                                <input onChange={e => setdate_period(e.target.value)} value={date_period} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'location *' : 'LOKASI'}
                                </p>
                                <input onChange={e => setLocation(e.target.value)} value={location} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'position *' : 'JABATAN *'}
                                </p>
                                <input onChange={e => setPosition(e.target.value)} value={position} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[8vh] text-[#4492f1] font-medium'>
                                {language === 'en' ? 'description *' : 'PENJELASAN *'}
                            </p>
                            {/* <p className='text-black mb-[2vh] font-normal'>Max 1000 Words</p> */}
                            <textarea onChange={e => setDescription(e.target.value)} value={description} className="bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] h-[40vh] border-[1px] px-[0.5vw] py-[0.3vw] rounded" ></textarea>
                        </div>
                        <div className='mt-[8vh]'>
                            <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium pb-[3vh]'>
                                {language === 'en' ? 'attachment' : 'LAMPIRAN'}
                            </h2>
                            <h2 className='text-black font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify uppercase dark:text-white'>
                                {language === 'en' ? 'resume/cover letter/academic transcripts' : 'RESUME/SURAT PENGANTAR/TRANSKRIP AKADEMIK'}
                            </h2>
                        </div>
                        {/* <div className='mt-[3vh]'>
                    <input type="file" id="file" name='file' className="" />
                    <label for="file" className="bg-[#4493f1] border-[1px] text-white border-[#4493f1] px-[2vw] py-[1vh] tablet:text-[14px] half:text-[12px] mobile:text-[12px] font-mulish font-medium hover:bg-[#123872] cursor-pointer rounded">Choose File</label>
                </div> */}
                        <Mailto label={language === 'en' ? "Send File" : 'Kirim File'} mailto="mailto:career@soemath.com" />
                    </form>
                </section>
                <input onClick={handleSubmitForm} type="submit" value={language === 'en' ? "Submit" : 'Kirim'} disabled={isLoading} className="mb-[10vh] px-[2vw] py-[1vh] bg-[#4493f1] rounded hover:bg-[#123872] text-white font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide" />

            </section>
        </>

    );
}

export default QualifiedLawyersForm;