import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NavbarHome from '../../../components/NavbarHome';
import Mailto from '../../../components/Mailto';
import { internshipForm } from '../../../apiService/careerPage';
import { Helmet } from 'react-helmet-async';

const InternForm = () => {
    const language = useSelector(state => state.language)

    const [isLoading, setIsLoading] = useState(false)

    const [first_name, setfirst_name] = useState('')
    const [last_name, setlast_name] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [domicile, setDomicile] = useState('')
    const [first_choice, setfirst_choice] = useState('')
    const [second_choice, setsecond_choice] = useState('')
    const [third_choice, setthird_choice] = useState('')
    const [education_level, seteducation_level] = useState('')
    const [name_of_school, setname_of_school] = useState('')
    const [major, setMajor] = useState('')
    const [GPA, setGPA] = useState('')
    const [year, setYear] = useState('')
    const [status, setStatus] = useState('')

    const handleSubmitForm = () => {
        setIsLoading(true)
        internshipForm({
            first_name,
            last_name,
            phone,
            domicile,
            email,
            locale: language,
            // "createdAt": "2023-05-04T14:37:54.933Z",
            // "updatedAt": "2023-05-04T14:38:03.750Z",
            // "publishedAt": "2023-05-04T14:38:03.743Z",
            first_choice,
            second_choice,
            third_choice,
            education_level,
            name_of_school,
            major,
            GPA,
            year,
            status
        })
            .then(res => {
                setIsLoading(false)
                alert('Form Sent Succesfully');
            })
            .catch(err => {
                setIsLoading(false)
                alert(err)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='items-start px-[5vw] pt-[15vh] bg-white dark:bg-dark'>
                <div className='flex flex-row justify-center'>

                </div>
                <div className=''>
                    <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium pb-[3vh]'>
                        {language === 'en' ? "intern/entry level APPLICATION" : "LAMARAN TINGKAT MAGANG/PEMULA "}
                    </h2>
                    <h2 className='text-black font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify dark:text-white uppercase'>
                        {language === 'en' ? 'Please complete each section of the application form in accordance with the instructions indicated.' : 'MOHON MENGISI SETIAP BAGIAN DALAM FORMULIR PENDAFTARAN INI SESUAI DENGAN ARAHAN YANG TELAH DIBERIKAN.'}
                    </h2>
                    <div className='mt-[8vh]'>
                        <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium'>
                            {language === 'en' ? 'personal information' : 'INFORMASI PRIBADI'}
                        </h2>
                    </div>
                </div>

                <div className="pt-[2vh] pb-[5vw] flex items-center w-[95%]">
                    <form className='flex flex-col flex-wrap justify-between w-[100%]'>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'first name *' : 'NAMA DEPAN *'}
                                </p>
                                <input onChange={e => setfirst_name(e.target.value)} value={first_name} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]  '>
                                    {language === 'en' ? 'last name *' : 'NAMA KELUARGA *'}
                                </p>
                                <input onChange={e => setlast_name(e.target.value)} value={last_name} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]  '>
                                    {language === 'en' ? 'no telephone/whatsapp *' : 'NO. TELEPON/WHATSAPP *'}
                                </p>
                                <input onChange={e => setPhone(e.target.value)} value={phone} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'email address *' : 'ALAMAT EMAIL *'}
                                </p>
                                <input onChange={e => setEmail(e.target.value)} value={email} type="email" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                            </div>
                        </div>
                        <div className='flex flex-col font-medium mr-[1.5vw]'>
                            <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]  '>
                                {language === 'en' ? 'domicile *' : 'DOMISILI *'}
                            </p>
                            <input onChange={e => setDomicile(e.target.value)} value={domicile} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                        </div>
                        <div>
                            <h2 className='text-black font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify uppercase mt-[4vh] dark:text-white mb-[2vh]'>
                                {language === 'en' ? 'CHOICE OF PREFERRED PRACTICE AREA' : 'MOHON MENGINDIKASIKAN DEPARTEMEN PILIHAN PERTAMA, KEDUA DAN KETIGA ANDA DALAM FIRMA INI'}
                            </h2>
                            <div className='grid tablet:grid-cols-3 med:grid-cols-2 half:grid-cols-1 mobile:grid-cols-1 grid-flow-row gap-4'>
                                <div className='flex flex-col'>
                                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>
                                        {language === 'en' ? 'first choice *' : 'PILIHAN PERTAMA *'}
                                    </p>
                                    <input onChange={e => setfirst_choice(e.target.value)} value={first_choice} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                                    {/* <select className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" onChange={e => setfirst_choice(e.target.value)} >
                        <option>Please Select</option>
                        <option>Banking & Finance</option>
                        <option>Capital Markets</option>
                        <option>Corporate & Commercial</option>
                        <option>Corporate / M&A</option>
                        <option>Employment</option>
                        <option>Financial Services</option>
                        <option>Private Equity</option>
                        <option>Carbon Transaction Ecosystem</option>
                        <option>Infestment Funds</option>
                        <option>Islamic Finance</option>
                        <option>Insurance/Sharia Insurance</option>
                        <option>FinTech</option>
                        <option>Data Protection</option>
                        <option>Carbon Trading</option>
                        <option>Energy & Resources</option>
                        <option>Oil & Gas</option>
                        <option>Power</option>
                        <option>Environment</option>
                        <option>Forestry</option>
                        <option>Public Private Partnership (PPP)</option>
                        <option>Environmental, Social & Governance</option>
                        <option>Project Finance</option>
                        <option>Administrative & Public Law</option>
                        <option>Arbitration</option> 
                        <option>Aviation</option>
                        <option>Restructuring & Insolvency</option>
                        <option>Commercial Litigation</option>
                        <option>Criminal Law Litigation</option>
                        <option>General Tax and Disputes</option>
                        <option>Crisis Management</option>
                        <option>General IP and Disputes</option>
                        <option>Shipping and Maritime</option>
                        <option>Competition and Antitrust</option>
                        <option>Real Estate</option>
                        <option>Construction</option>
                        <option>Technology, Media & Telecommunication</option>
                        <option>Intellectual Property</option>
                        <option>Product Liability</option>
                        <option>Healthcare & Pharmaceuticals</option>
                        <option>Insurance</option>
                        <option>Shipping</option>
                        <option>Hospitality</option>
                        <option>Anti-bribery and Corruption</option>
                        <option>Government Procerement</option>
                        
                    </select> */}
                                </div>
                                <div className='flex flex-col'>
                                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>
                                        {language === 'en' ? 'second choice *' : 'PILIHAN KEDUA *'}
                                    </p>
                                    <input onChange={e => setsecond_choice(e.target.value)} value={second_choice} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                                </div>
                                <div className='flex flex-col'>
                                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]  font-medium'>
                                        {language === 'en' ? 'third choice *' : 'PILIHAN KETIGA *'}
                                    </p>
                                    <input onChange={e => setthird_choice(e.target.value)} value={third_choice} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                                </div>
                            </div>
                        </div>
                        <div className='mt-[8vh]'>
                            <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium pb-[3vh]'>
                                {language === 'en' ? 'formal education' : 'PENDIDIKAN FORMAL'}
                            </h2>
                            <h2 className='text-black font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify dark:text-white uppercase'>
                                {language === 'en' ? 'PROVIDE YOUR MOST RECENT FORMAL EDUCATION' : 'MOHON MEMBERIKAN INFORMASI TERKAIT JENJANG PENDIDIKAN FORMAL TERAKHIR ANDA'}
                            </h2>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'level *' : 'TINGKAT *'}
                                </p>
                                <input onChange={e => seteducation_level(e.target.value)} value={education_level} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'name of school *' : 'NAMA PERGURUAN TINGGI *'}
                                </p>
                                <input onChange={e => setname_of_school(e.target.value)} value={name_of_school} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'major *' : 'PROGRAM STUDI *'}
                                </p>
                                <input onChange={e => setMajor(e.target.value)} value={major} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'gpa *' : 'IPK *'}
                                </p>
                                <input onChange={e => setGPA(e.target.value)} value={GPA} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'year *' : 'TAHUN *'}
                                </p>
                                <input onChange={e => setYear(e.target.value)} value={year} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium mr-[1.5vw]'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'status *' : 'STATUS *'}
                                </p>
                                <input onChange={e => setStatus(e.target.value)} value={status} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='mt-[8vh]'>
                            <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify text-[#4493f1] uppercase text-[#4493f1] font-medium pb-[3vh]'>
                                {language === 'en' ? 'attachment' : 'LAMPIRAN'}
                            </h2>
                            <h2 className='text-black font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify dark:text-white uppercase'>
                                {language === 'en' ? 'resume/cover letter/academic transcripts' : 'RESUME/SURAT PENGANTAR/TRANSKRIP AKADEMIK'}
                            </h2>
                        </div>
                        <Mailto label={language === 'en' ? "Send File" : "Kirim File"} mailto="mailto:career@soemath.com" />
                    </form>
                </div>
                <input onClick={handleSubmitForm} type="submit" value={language === 'en' ? "Submit" : "Kirim"} disabled={isLoading} className="mb-[10vh] px-[2vw] py-[1vh] bg-[#4493f1] rounded hover:bg-[#123872] text-white font-mulish tablet:text-[18px] mobile:text-[14px] font-normal tracking-wide" />
            </section>
        </>

    );
}

export default InternForm;