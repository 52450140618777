import React, { useEffect, useState } from 'react';
import { FaRegPaperPlane } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import { TbDownload } from 'react-icons/tb';
import { useSelector } from 'react-redux';

import NavbarHome from '../../NavbarHome';
import SubFooter from '../../SubFooter';

import { getPostDetail } from '../../../apiService/perspectivePage';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Post = () => {
    const param = useParams();
    const { pathname } = useLocation()
    const token = useSelector(state => state.token)
    const language = useSelector(state => state.language)

    const [data, setData] = useState({})

    function copyToClipboard(text) {
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }

    useEffect(() => {
        getPostDetail(param.id, token, language)
            .then(res => {
                setData(res);
            })
            .catch(err => null)
    }, [language])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='bg-light dark:bg-dark px-[5vw] py-[10vh]'>
                <div className='flex flex-col tablet:pt-[5vh] '>
                    <ul className=''>
                        <h1 className='font-mulish dark:text-white tablet:text-[28px] mobile:text-[22px] mb-[4vh] tablet:leading-[40px] mobile:leading-[30px]'>{data?.title}</h1>
                    </ul>
                    <div>
                        <img src={data?.image} alt='' className='w-[100%] h-auto'></img>
                        <p className='font-mulish tablet:text-[18px] mobile:text-[12px] text-justify uppercase text-[#4493f1] mt-[2vh]'>
                            {language === 'en'
                                ? data?.category
                                : data?.category === 'Published Articles'
                                    ? 'Berita Terkait'
                                    : 'Informasi Terkini'
                            }
                        </p>
                    </div>
                    <div>
                        <div className=''>
                            {
                                data?.content?.map((item, index) => item.includes('**')
                                    ? (
                                        <p key={index} className='font-mulish dark:text-white tablet:text-[20px] mobile:text-[14px] font-bold text-justify tablet:my-[3vh] mobile:my-[2vh]'>{item.replaceAll('**', '')}</p>
                                    )
                                    : (item.includes('http') && !item.includes('img-')) || item.includes('<u>')
                                        ? <div key={index} dangerouslySetInnerHTML={{ __html: item }} className="font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify tablet:my-[3vh] mobile:my-[1vh]" />
                                        : item.includes('img-')
                                            ? <img key={index} src={item.replace('img-', '')} alt='Image' className='w-[80%] h-auto' />
                                            : (
                                                <p key={index} className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify tablet:my-[3vh] mobile:mt-[1vh]'>{item}</p>
                                            ))
                            }
                        </div>
                    </div>
                    <div className='flex flex-row w-[100%] tablet:mt-[6vh] mobile:mt-[3vh]'>
                        <div className='flex flex-row items-center'>
                            <a href={data?.article_pdf} download={data?.article_pdf} target='_blank' rel='noopener noreferrer' className='flex items-center justify-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] rounded px-[1vw] tablet:py-[0.5vh] mobile:py-[1.5vh] bg-[#4493f1] text-white hover:bg-[#123872] mr-[1vw] tablet:mb-[3vh] mobile:mb-[1vh]'>
                                DOWNLOAD FULL ARTICLE<TbDownload className='ml-[1vw]' />
                            </a>
                        </div>
                        <div className='flex flex-row items-center'>
                            <a href='https://linkedin.com' target='_blank' className='flex items-center justify-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] rounded px-[1vw] tablet:py-[0.5vh] mobile:py-[1.5vh] bg-[#4493f1] text-white hover:bg-[#123872] mr-[1vw] tablet:mb-[3vh] mobile:mb-[1vh]' rel="noreferrer">
                                SHARE VIA LINKEDIN<FaRegPaperPlane className='ml-[1vw]' />
                            </a>
                        </div>
                        <div className='flex flex-row items-center'>
                            <Link className="" to={`mailto:`} >
                                <button onClick={() => copyToClipboard(`${process.env.REACT_APP_DASHBOARD_URL}${pathname}`)} className='flex items-center justify-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] rounded px-[1vw] tablet:py-[0.5vh] mobile:py-[1.5vh] bg-[#4493f1] text-white hover:bg-[#123872] mr-[1vw] tablet:mb-[3vh] mobile:mb-[1vh]'>
                                    SHARE VIA EMAIL<FaRegPaperPlane className='ml-[1vw]' />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div >
                        <h1 className='font-mulish dark:text-white tablet:text-[25px] mobile:text-[20px] tablet:mb-[5vh] tablet:mt-[10vh] mobile:mt-[6vh]'>
                            {language === 'en' ? 'AUTHORED BY' : 'DITULIS OLEH'}
                        </h1>
                        <div className='grid tablet:grid-cols-4 half:grid-cols-3 mobile:grid-cols-2 grid-flow-row tablet:gap-4 mobile:gap-2 flex w-full'>
                            {data?.partners?.map((item, index) => (
                                <div key={index} className='mr-[4vw] tabelet:mb-[5vh] mobile:mb-[2vh]'>
                                    <img src={item.url} alt='authors' className='tablet:w-[20vw] mobile:w-[25vw] py-[2vh]'></img>
                                    <a href={`/people/${item.full_name}`}><p className='description1 dark:text-white hover:underline tablet:mb-[1vh]'>{item.full_name}</p></a>
                                    <p className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] tablet:leading-[5vh] mobile:leading-[3vh] '>{item.type}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <SubFooter />
        </>

    );
}

export default Post;