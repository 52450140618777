import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ClientUpdate = ({ dataItems = [] }) => {
    const language = useSelector(state => state.language)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const sortDesc = (a, b) => {
        if (a.id > b.id) {
            return -1;
        } else if (a.id < b.id) {
            return 1;
        } else {
            return 0;
        }
    }

    return (
        <>
            <section className='grid tablet:grid-cols-4 half:grid-cols-3 mobile:grid-cols-2 grid-flow-row gap-4 flex w-full mr-[5vw] mt-[10vh]'>
                {dataItems.map((item, index) => (
                    <div className="flex" key={index}>
                        <div className='flex flex-col font-mulish dark:text-white leading-[25px] tracking-wide tablet:text-[16px] half:text-[14px] mobile:text-[12px] font-normal hover:scale-110 hover:bg-white dark:hover:bg-[#25282C] duration-500 tablet:w-[20vw] mobile:w-[44vw] border-2 hover:shadow-md dark:border-[#31353A] p-4'>
                            <a href={`/news-and-insights/post/${item.slug}`} >
                                <p className='font-bold'>{item.title}</p>
                                <img src={item.image} className='w-[100%] h-auto py-[2vh]'></img>
                                <p className='opacity-[80%] mb-[1vh]'>{language === 'en' ? item.category : (item.category === 'Client Updates' ? 'Informasi Terkini' : 'Berita Terkait')}</p>
                            </a>
                        </div>
                    </div>
                ))}
            </section>
        </>

    );
}

export default ClientUpdate;