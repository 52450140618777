import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavbarHome from '../../components/NavbarHome';
import Captcha from '../../components/Captcha';
import { mailingForm } from '../../apiService/mailingPage';
import { Helmet } from 'react-helmet-async';

const MailingList = () => {
    const language = useSelector(state => state.language)

    const [disabled, setDisabled] = useState(false)

    const [salutation, setSalutation] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [emailAddress, setEmailAddress] = useState('')

    const [user, setUser] = useState({
        username: ""
    });

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        user[name] = value;
        setUser(user);
    }

    const characters = 'abc123';

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const captcha = generateString(6)

    const onSubmit = e => {
        setDisabled(true)
        var element = document.getElementById("succesBTN");
        var inputData = document.getElementById("inputType");

        //  element.style.cursor = "wait";
        //  element.innerHTML  = "Checking...";

        inputData.disabled = true;
        element.disabled = true;

        if (
            !salutation ||
            salutation.includes('Select') ||
            salutation.includes('Mohon Pilih') ||
            !firstName ||
            !lastName ||
            !companyName ||
            !emailAddress
        ) {
            setDisabled(false)
            alert(language === 'en' ? 'All Columns must be Filled' : 'Semua kolom harus terisi')
        } else {
            if (captcha === user.username) {
                const mailingData = {
                    salutation,
                    first_name: firstName,
                    last_name: lastName,
                    company_name: companyName,
                    business_email_address: emailAddress
                }

                mailingForm(mailingData)
                    .then(res => {
                        element.disabled = false;
                        alert(language === 'en' ? 'Successful Subscription' : 'Sukses Berlangganan')
                    })
                    .catch(err => {
                        setDisabled(false)
                        element.disabled = false;
                        alert(language === 'en' ? 'Failed to Subscribe' : 'Gagal Berlangganan')
                    })
                // element.style.backgroundColor   = "green";
                // element.innerHTML  = "Captcha Verified";
                // element.disabled = true;
                // element.style.cursor = "not-allowed";
                // inputData.style.display = "none";
            } else {
                // element.style.backgroundColor   = "red";
                // element.style.cursor = "not-allowed";
                // element.innerHTML  = "Not Matched";
                // element.disabled = true;
                //  element.disabled = true;

                element.style.backgroundColor = "#007bff";
                element.style.cursor = "pointer";
                //   element.innerHTML  = "Verify Captcha";
                element.disabled = false;
                inputData.disabled = false;
                // inputData.value ='sssss';
                setDisabled(false)
                alert(language === 'en' ? 'Wrong Caption!' : 'Caption Salah!')
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='items-start bg-white dark:bg-dark pl-[5vw] pt-[15vh]'>

                <div className='w-[88vw]'>
                    <h2 className='font-medium font-mulish tablet:text-[22px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] tracking-wide text-[#4493f1] pb-[3vh]'>
                        {language === 'en' ? 'Join our mailing list' : 'Bergabunglah dengan milis kami'}
                    </h2>
                    <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal dark:text-white tracking-wide text-jusify pb-[3vh]'>
                        {language === 'en' ? 'If you wish to be on our mailing list to receive electronic communications (including bulletins, updates, alerts, reviews, invitations and announcements) from Soemadipradja & Taher, please complete the form below.' : 'Jika Anda ingin bergabung dalam daftar milis kami untuk menerima komunikasi elektronik (termasuk buletin, pembaruan, pemberitahuan, ulasan, undangan, dan pengumuman) dari Soemadipradja & Taher, silakan lengkapi formulir di bawah ini.'}
                    </h2>
                    <div className='mt-[5vh]'>
                        <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide uppercase text-[#4493f1] font-medium'>
                            {language === 'en' ? 'contact details' : 'RINCIAN KONTAK'}
                        </h2>
                    </div>
                </div>

                <section className="pt-[2vh] pb-[5vw] flex items-center w-[95%]">
                    <form className='flex flex-col flex-wrap justify-between'>
                        <div className='flex flex-row justify-between w-[88vw]'>
                            <div className='flex flex-col'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide uppercase mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>
                                    {language === 'en' ? 'salutation *' : 'SAPAAN *'}
                                </p>
                                <select className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white tablet:w-[25vw] mobile:w-[30vw] half:w-[27vw] med:w-[26vw] px-[0.5vw] py-[0.3vw] rounded" onChange={e => setSalutation(e.target.value)} >
                                    <option>{language === 'en' ? 'Please Select' : 'Mohon Pilih'}</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mdm">Mdm</option>
                                    <option value="Dr">Dr</option>
                                </select>
                            </div>
                            <div className='flex flex-col font-medium'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                    {language === 'en' ? 'first name *' : 'NAMA DEPAN *'}
                                </p>
                                <input onChange={e => setFirstName(e.target.value)} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[25vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                            <div className='flex flex-col font-medium'>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide uppercase mb-[2vh] mt-[4vh] text-[#4492f1] '>
                                    {language === 'en' ? 'last name *' : 'NAMA BELAKANG *'}
                                </p>
                                <input onChange={e => setLastName(e.target.value)} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[25vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                            </div>
                        </div>
                        <div className='flex flex-col font-medium'>
                            <p className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                {language === 'en' ? 'company name' : 'NAMA PERUSAHAAN'}
                            </p>
                            <input onChange={e => setCompanyName(e.target.value)} type="text" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                        </div>
                        <div className='flex flex-col font-medium'>
                            <p className='font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide uppercase mb-[2vh] mt-[4vh] text-[#4492f1]'>
                                {language === 'en' ? 'business email address *' : 'ALAMAT EMAIL PERUSAHAAN *'}
                            </p>
                            <input onChange={e => setEmailAddress(e.target.value)} type="email" className="border-[1px] block bg-white dark:bg-dark dark:border-black text-black dark:text-white w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                        </div>
                        <p className='mt-[8vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] tablet:leading-[30px] mobile:leading-[25px] text-justify font-normal tracking-wide'>
                            {language === 'en' ? 'Please note that, by sending this completed form, you have agreed to receive electronic communications from Soemadipradja & Taher, and allow us to use your personal data to send these communications to you. Should you need any clarification, please do not hesitate to contact us at +62 21 - 50 999 879 or center@soemath.com. We thank you for sending in your completed subscription form.' : 'Mohon perhatikan bahwa, dengan mengirimkan formulir yang telah dilengkapi ini, Anda telah setuju untuk menerima komunikasi elektronik dari Soemadipradja & Taher, dan mengizinkan kami untuk menggunakan data pribadi Anda untuk mengirimkan komunikasi tersebut kepada Anda. Jika Anda memerlukan penjelasan lebih lanjut, silakan hubungi kami di +62 21 - 50 999 879 atau center@soemath.com. Terima kasih telah mengirimkan formulir berlangganan Anda yang telah dilengkapi.'}
                        </p>
                    </form>
                </section>
                <div className=''>
                    <Captcha
                        captcha={captcha}
                        handleChange={handleChange}
                        onSubmit={onSubmit}
                        disabled={disabled}
                        language={language}
                    />
                </div>

            </section>
        </>

    );
}

export default MailingList;