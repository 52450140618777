import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NavbarHome from '../../../../components/NavbarHome';

import { TbDownload } from 'react-icons/tb';
import { IoIosArrowForward } from 'react-icons/io';
import { HiOutlineCreditCard } from 'react-icons/hi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';

import SubFooter from '../../../../components/SubFooter';
import { getPeopleDetail } from '../../../../apiService/peoplePage';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PartnersPeople = () => {
    const navigate = useNavigate();
    const param = useParams();
    const token = useSelector(state => state.token)
    const language = useSelector(state => state.language)

    const [data, setData] = useState({})

    useEffect(() => {
        getPeopleDetail(param.id, token, language)
            .then(res => {
                setData(res);
            })
            .catch(err => null)
    }, [language])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='bg-light dark:bg-dark py-[10vh] px-[5vw]'>
                <div className='mb-[15vh]'>
                    <ul className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className='flex tablet:flex-row half:flex-row med:flex-row mobile:flex-col caption-card w-[88vw] tablet:h-[38vh] half:h-[30vh] mobile:h-max bg-[#DBDCDD] dark:bg-black dark:bg-black my-[5vh]'>
                                <img src={data?.image} alt={data?.name} className='tablet:img-fluid'></img>
                                <div className='flex flex-col tablet:pl-[2vw] med:pl-[2vw] half:pl-[2vw] mobile:pl-[5vw] justify-center w-full py-[2vh]'>
                                    <p className='font-mulish dark:text-white tablet:text-[30px] mobile:text-[14px] tablet:pb-[1vh] font-bold'>{data?.name}</p>
                                    <p className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] tablet:pb-[3vh] mobile:pb-[1vh]'>{data?.type}</p>
                                    <div>
                                        <div className='flex flex-row items-center justify-center tablet:mb-[1.5vh]'>
                                            <FiMail size={20} className='mr-[1vw] dark:text-white' />
                                            <button
                                                className="relative flex justify-start overflow-hidden transition duration-300 ease-out text-black font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide w-full group"
                                            >
                                                <span className='absolute w-max items-center justify-center h-full duration-300 -translate-x-full bg-[#DBDCDD] dark:bg-black group-hover:translate-x-0 ease'>
                                                    <Link className="hover:underline"
                                                        to={`mailto:${data?.email}`}
                                                    >
                                                        Write me an email
                                                    </Link>
                                                </span>
                                                <span className='flex w-max items-center justify-center bg-[#DBDCDD] dark:bg-black'>Email<IoIosArrowForward className='ml-[1vh]' /></span>
                                            </button>
                                        </div>
                                        <div className='flex flex-row items-center justify-center tablet:mb-[1.5vh]'>
                                            <HiOutlineCreditCard size={20} className='mr-[1vw] dark:text-white' />
                                            <button
                                                className="relative flex justify-start overflow-hidden transition duration-300 ease-out text-black font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide w-full group"
                                            >
                                                <span className='absolute w-max items-center justify-center h-full duration-300 -translate-x-full bg-[#DBDCDD] dark:bg-black group-hover:translate-x-0 ease'>
                                                    <a href={`data:text/x-vcard;charset=utf-8,${data?.vcardContent}`} download={`${data?.name}.vcf`} className='hover:underline'>Download VCard</a>
                                                </span>
                                                <span className='flex w-max items-center justify-center bg-[#DBDCDD] dark:bg-black'>VCard<IoIosArrowForward className='ml-[1vh]' /></span>
                                            </button>
                                        </div>
                                        <div className='flex flex-row items-center justify-center tablet:mb-[1.5vh]'>
                                            <AiOutlineLinkedin size={20} className='mr-[1vw] dark:text-white' />
                                            <button
                                                className="relative flex justify-start overflow-hidden transition duration-300 ease-out text-black font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide w-full group"
                                            >
                                                <span className='absolute w-max items-center justify-center h-full duration-300 -translate-x-full bg-[#DBDCDD] dark:bg-black group-hover:translate-x-0 ease'><a href={data?.linkedin} target='_blank' className='hover:underline' rel="noreferrer">Linkedin Profile</a></span>
                                                <span className='flex w-max items-center justify-center bg-[#DBDCDD] dark:bg-black'>Linkedin<IoIosArrowForward className='ml-[1vh]' /></span>
                                            </button>
                                        </div>
                                        <a href={data?.resume} target='_blank' download={data?.resume} rel="noreferrer">
                                            <TbDownload size={25} className='mt-[2vh] text-[#4493f1] hover:text-[#123872]' />
                                        </a></div>

                                </div>
                            </div>
                        </div>
                        <div className='w-[88vw]'>

                            <div id='profile'>
                                {data?.description && data?.description.map((item, index) => (
                                    <p key={index} className='tablet:mb-[2vh] mobile:mb-[1vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify'>{item}</p>
                                ))}
                            </div>

                            {data?.workHighlights?.length && (
                                <div id='work-highlights'>
                                    <p className='caption-about mb-[2vh] uppercase text-[#4493f1] font-bold tablet:mt-[5vh] mobile:mt-[3vh] uppercase'>
                                        {language === 'en' ? 'Work Highlights' : 'Sorotan Pekerjaan'}
                                    </p>
                                    <ul className='ml-[2vw]'>
                                        {data?.workHighlights && data?.workHighlights.map((item, index) => (
                                            <li key={index} type="disc" className='mb-[0.5vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify'>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div id='qualifications'>
                                <p className='caption-about mb-[2vh] uppercase text-[#4493f1] font-bold tablet:mt-[5vh] mobile:mt-[3vh] uppercase'>
                                    {language === 'en' ? 'Qualifications' : 'Kualifikasi'}
                                </p>
                                <ul className='ml-[2vw]'>
                                    {data?.qualifications && data?.qualifications.map((item, index) => (
                                        <li key={index} type="disc" className='mb-[0.5vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify'>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <div id='education'>
                                <p className='caption-about mb-[2vh] uppercase text-[#4493f1] font-bold tablet:mt-[5vh] mobile:mt-[3vh] uppercase'>
                                    {language === 'en' ? 'Languages' : 'Bahasa'}
                                </p>
                                <p className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px]'>{data?.language}</p>
                            </div>


                            {data?.awards?.length && (
                                <div id='awards'>
                                    <p className='caption-about mb-[2vh] uppercase text-[#4493f1] font-bold tablet:mt-[5vh] mobile:mt-[3vh] uppercase'>
                                        {language === 'en' ? 'Awards' : 'Penghargaan'}
                                    </p>
                                    <ul className='ml-[2vw]'>
                                        {data?.awards && data?.awards.map((item, index) => (
                                            <li key={index} type="disc" className='mb-[0.5vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify'>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div id='key-practice'>
                                <p className='caption-about mb-[2vh] uppercase text-[#4493f1] font-bold tablet:mt-[5vh] mobile:mt-[3vh] uppercase'>
                                    {language === 'en' ? 'practice area and industry' : 'Area Praktik dan Industri'}
                                </p>
                                <ul className='ml-[2vw]'>
                                    {data?.practice_area_and_industry && data?.practice_area_and_industry.map((item, index) => ((
                                        <a key={index} onClick={() => navigate(`/capabilities/practice-area/${item.toLowerCase().replaceAll(' ', '-')}`, { state: item })} className='hover:underline'>
                                            <li type="disc" className='mb-[0.5vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify'>{item}</li>
                                        </a>
                                    )))}
                                </ul>
                            </div>

                            {data?.publications?.length && (
                                <div id='publications'>
                                    <p className='caption-about mb-[2vh] uppercase text-[#4493f1] font-bold tablet:mt-[5vh] mobile:mt-[3vh] uppercase'>
                                        {language === 'en' ? 'Publications' : 'Publikasi'}
                                    </p>
                                    <ul className='ml-[2vw]'>
                                        {data?.publications && data?.publications.map((item, index) => item.includes('<a')
                                            ? (
                                                <li key={index} type="disc" dangerouslySetInnerHTML={{ __html: item }} className='mb-[0.5vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify' />
                                            )
                                            : <li key={index} type="disc" className='mb-[0.5vh] font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] text-justify'>{item}</li>
                                        )
                                        }
                                    </ul>
                                </div>
                            )}
                        </div>
                    </ul>
                </div>

            </section>
            <SubFooter />
        </>

    );
}

export default PartnersPeople;