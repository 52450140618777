import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";

const CarouselHome = ({ slides }) => {
    const navigate = useNavigate();
    const language = useSelector(state => state.language)

    const [current, setCurrent] = useState(0)
    const timeout = useRef(null)

    const resetTime = () => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(nextSlide, 5000)
    }

    useEffect(() => {
        resetTime()
        return () => {
            clearTimeout(timeout.current)
        }
        // eslint-disable-next-line
    }, [current, slides])

    const prevSlide = () => {
        setCurrent(current === 0 ? slides.length - 1 : current - 1)

    }

    const nextSlide = () => {
        setCurrent(current === slides.length - 1 ? 0 : current + 1)
    }

    return (
        <>
            <div
                style={{ transform: `translateX(0)` }}
                className="flex items-center w-[100%] tablet:h-[65vh] mobile:h-[32vh] mt-[4vh] group">
                <div style={{ backgroundImage: `url(${slides[current]?.image})` }} className="flex items-center justify-center w-full h-full bg-center bg-cover duration-500 ease">
                    <div className="flex flex-row items-center justity-between">
                        <div className="hidden group-hover:block absolute bg-white rounded-full left-5 -translate-x-0 translate-y-[-50] tablet:text-2xl mobile:text-normal text-black">
                            <MdKeyboardArrowLeft
                                onClick={prevSlide}
                                size={40} className="hover:cursor-pointer" />
                        </div>
                        <div className="hidden group-hover:block absolute bg-white rounded-full right-5 -translate-x-0 translate-y-[-50] tablet:text-2xl mobile:text-normal text-black">
                            <MdKeyboardArrowRight
                                onClick={nextSlide}
                                size={40} className="hover:cursor-pointer" />
                        </div>
                    </div>
                    <div className="flex items-center justify-center bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-50 h-full w-full">
                        <div className="flex flex-col items-center justify-center">
                            <p className="font-mulish dark:text-white tablet:text-[25px] mobile:text-[16px] mb-[1.5vh]">{language === 'en' ? slides[current]?.title : (slides[current]?.title === 'Client Updates' ? 'Informasi Terkini' : 'Berita Terkait')}</p>
                            <h1 className="flex font-mulish dark:text-white tablet:text-[32px] tablet:leading-[60px] mobile:leading-[20px] tracking-wide mobile:text-[18px] font-bold text-center tablet:w-[90%] mobile:w-[90%] tablet:mb-[5vh] mobile:mb-[2.5vh]">{slides[current]?.description}</h1>
                            <button
                                onClick={() => navigate(`/news-and-insights/post/${slides[current]?.slug}`)}
                                className='bg-[#636568] dark:bg-[#4493f1] dark:hover:bg-[#123872] hover:bg-[#000] font-mulish tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide uppercase tablet:px-[2.5vw] mobile:px-[2vw] tablet:py-[1.5vh] mobile:py-[1.2vh] rounded text-white'
                            >
                                {language === 'en' ? 'Learn More' : 'Informasi Lebih Lanjut'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-[3vh] justify-center gap-2">
                {slides.map((slide, index) => (
                    <div
                        onClick={() => setCurrent(index)}
                        key={slide.id}
                        className={`tablet:w-[1vw] tablet:h-[1vw] med:w-[1.2vw] med:h-[1.2vw] half:w-[1.5vw] half:h-[1.5vw] mobile:w-[2.2vw] mobile:h-[2.2vw] rounded-full cursor-pointer ${index === current ? 'bg-[#000]' : 'bg-[#d9d9d9]'}`}
                    >
                    </div>
                ))}
            </div>
        </>
    )
}

export default CarouselHome