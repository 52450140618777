import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Footer from '../../components/Footer';
import NavbarHome from '../../components/NavbarHome';
import Achievement from '../../components/Achievement';
import CarouselHome from '../../components/CarouselHome';

import { getHomeCapabilitiesPage, getHomePage } from '../../apiService/homePage';
import getAboutUsPage from '../../apiService/about-usPage';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    const navigate = useNavigate();
    const language = useSelector(state => state.language)

    const [data, setData] = useState({
        carousel: [],
        achievements: [],
    })
    const [dataAboutUs, setDataAboutUs] = useState({})
    const [dataCapabilities, setDataCapabilities] = useState('')

    useEffect(() => {
        getHomePage(language)
            .then(res => {
                setData(res)
            })
            .catch(err => null)

        getAboutUsPage(language)
            .then(res => {
                setDataAboutUs(res)
            })
            .catch(err => null)

        getHomeCapabilitiesPage(language)
            .then(res => {
                setDataCapabilities(res);
            })
            .catch(err => null)
    }, [language])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />
            <section className='px-[5vw] tablet:pt-[10vh] mobile:pt-[8vh] tablet:pb-[20vh] mobile:pb-[16vh] bg-light dark:bg-dark z-0'>
                <CarouselHome slides={data?.carousel} />

                <div className='flex flex-col about tablet:pb-[10vh] mobile:pb-[5vh]'>
                    <h1 className='caption-capabilities dark:text-white'>{language === 'en' ? 'Capabilities' : 'Kemampuan'}</h1>
                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[40px] mobile:leading-[25px] font normal tracking-wide text-center text-black dark:text-white w-[70%] pt-[4vh] pb-[6vh]'>{dataCapabilities}</p>
                    <button
                        onClick={() => navigate('/capabilities')}
                        className='bg-[#636568] dark:bg-[#4493f1] dark:hover:bg-[#123872] hover:bg-[#000] font-mulish tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide uppercase tablet:px-[2.5vw] mobile:px-[2vw] tablet:py-[1.5vh] mobile:py-[1.2vh] rounded text-white'
                    >
                        {language === 'en' ? 'find more our capabilities' : 'temukan lebih banyak kapabilitas kami'}
                    </button>
                </div>

                <div id='section-1' className='flex flex-col justify-center items-center tablet:py-[8vh] mobile:py-[3vh]'>
                    <h1 className='caption-capabilities dark:text-white'>
                        {language === 'en' ? 'Achievement' : 'Prestasi'}
                    </h1>
                    <Achievement data={data?.achievements} />
                </div>

                <div className='flex flex-col about tablet:pb-[10vh] mobile:pb-[5vh]'>
                    <h1 className='caption-capabilities dark:text-white'>{dataAboutUs?.post?.title || 'About Us'}</h1>
                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[40px] mobile:leading-[25px] font normal tracking-wide text-center text-black dark:text-white w-[70%] pt-[4vh] pb-[6vh]'>{dataAboutUs?.title}</p>
                    <button
                        onClick={() => navigate('/about-us')}
                        className='bg-[#636568] dark:bg-[#4493f1] dark:hover:bg-[#123872] hover:bg-[#000] font-mulish tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide uppercase tablet:px-[2.5vw] mobile:px-[2vw] tablet:py-[1.5vh] mobile:py-[1.2vh] rounded text-white'
                    >
                        {language === 'en' ? 'Learn More' : 'Informasi Lebih Lanjut'}
                    </button>
                </div>
            </section>
            <Footer />
        </>

    );
}

export default Home;