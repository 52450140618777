import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SubFooter from '../../components/SubFooter';
import NavbarHome from '../../components/NavbarHome';

import { getAllPracticeAreas, getPeoplePage } from '../../apiService/peoplePage';
import Pagination from '../../components/Pagination';
import { Helmet } from 'react-helmet-async';

const Partners = () => {
    const token = useSelector(state => state.token)
    const language = useSelector(state => state.language)

    const sortDesc = (a, b) => {
        if (language === 'en' ? a.name < b.name : a.name_bahasa < b.name_bahasa) {
            return -1
        }
        if (language === 'en' ? a.name > b.name : a.name_bahasa > b.name_bahasa) {
            return 1
        }
        return 0
    }

    const [searchTerm, setSearchTerm] = useState('');

    const [filter, setFilter] = useState('')
    const [filterPracticeArea, setFilterPracticeArea] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [practiceArea, setPracticeArea] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
        // getPeoplePage(token, filter, filterPracticeArea == 'Practice Area' ? '' : filterPracticeArea, currentPage, postsPerPage)
        // .then(res => {
        //     setFilteredData(res.data);
        //     setTotal(res?.pagination?.total);
        // })
        // .catch(err => null)

        getAllPracticeAreas(token)
            .then(res => {
                setPracticeArea(res);
            })
            .catch(err => null)
    }, [])

    useEffect(() => {
        setFilteredData([])
        getPeoplePage(token, filter, filterPracticeArea == 'Practice Area' ? '' : filterPracticeArea, currentPage, postsPerPage)
            .then(res => {
                setFilteredData(res.data);
                setTotal(res?.pagination?.total);
            })
            .catch(err => null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, filterPracticeArea, currentPage])

    return (
        <>
            <Helmet>
                {/* Google Tag Manager */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-PJPKW75M7X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-PJPKW75M7X');
                    `}
                </script>
            </Helmet>
            <NavbarHome />

            <section className='bg-light dark:bg-dark tablet:pt-[10vh] mobile:pt-[5vh] flex flex-col pb-[30vh]'>
                <div className='mt-[5vh] px-[5vw]'>
                    <form className="flex items-center" onChange={event => setSearchTerm(event.target.value)}>
                        <div className="relative tablet:w-[23%] desktop:w-[23%] med:w-[24%] half:w-[25%] mobile:w-[40%]">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                            </div>
                            <input type="text" id="simple-search" className="bg-[#f9f9f9] border border-[#d9d9d9] text-grey-500 font-mulish desktop:text-[16px] tablet:text-[14px] mobile:text-[12px] half:text-[12px] med:text-[12px] rounded-lg focus:ring-[#4493f1] focus:border-[#4493f1] block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4493f1] dark:focus:border-[#4493f1]" placeholder="Search" required />
                        </div>
                    </form>
                    <div className='mt-[2.5vh] tablet:w-[23%] desktop:w-[23%] med:w-[24%] half:w-[25%] mobile:w-[40%]'>
                        <select
                            onChange={e => {
                                e.preventDefault()
                                setCurrentPage(1)
                                setFilterPracticeArea(e.target.value);
                            }}
                            className="select-css-partner dark:select-css-partner-dark font-mulish desktop:text-[16px] tablet:text-[14px] mobile:text-[12px] half:text-[12px] med:text-[12px] rounded-lg focus:ring-[#4493f1] focus:border-[#4493f1] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4493f1] dark:focus:border-[#4493f1]"
                        >
                            <option selected className='bg-white dark:bg-black text-black dark:text-white border-transparent'>{language === 'en' ? 'Practice Areas' : 'Area Praktik'}</option>
                            {
                                practiceArea.sort(sortDesc).map((item, index) => {
                                    return (
                                        <option key={index} value={item.name} className='bg-white dark:bg-black text-black dark:text-white border-transparent'>
                                            {language === 'en' ? item.name : item.name_bahasa}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>

                </div>
                <div>
                    <div className='flex flex-row bg-[#F5F5F9] dark:bg-black w-[100%] h-[12vh] mt-[6vh] justify-center items-center'>
                        <button
                            onClick={() => { setCurrentPage(1); setFilter("Partner") }}
                            className={filter === 'Partner' ? 'font-mulish font-normal tablet:w-[15vw] half:w-[20vw] mobile:w-[25vw] text-[#4493f1] tablet:text-[22px] half:text-[18px] mobile:text-[16px]' : 'font-mulish font-normal tablet:w-[15vw] half:w-[20vw] mobile:w-[25vw] text-[#32475c] dark:text-white dark:opacity-[60%] hover:text-[#4493f1] opacity-[70%] tablet:text-[22px] half:text-[18px] mobile:text-[16px]'}
                        >
                            {language === 'en' ? 'Partners' : 'Mitra'}
                        </button>
                        <button
                            onClick={() => { setCurrentPage(1); setFilter("Counsel") }}
                            className={filter === 'Counsel' ? 'font-mulish font-normal tablet:w-[15vw] half:w-[20vw] mobile:w-[25vw] h-[70%] text-[#4493f1] tablet:text-[22px] half:text-[18px] mobile:text-[16px]' : 'font-mulish font-normal hover:text-[#4493f1] tablet:w-[15vw] half:w-[20vw] mobile:w-[25vw] h-[70%] text-[#32475c] dark:text-white dark:opacity-[60%] opacity-[70%] tablet:text-[22px] half:text-[18px] mobile:text-[16px]'}>
                            {language === 'en' ? 'Counsels' : 'Konsel'}
                        </button>
                        <button
                            onClick={() => { setCurrentPage(1); setFilter("Senior Associate") }}
                            className={filter === 'Associate' ? 'font-mulish font-normal tablet:w-[15vw] half:w-[20vw] mobile:w-[25vw] text-[#4493f1] tablet:text-[22px] half:text-[18px] mobile:text-[16px]' : 'font-mulish font-normal tablet:w-[15vw] half:w-[20vw] mobile:w-[25vw] text-[#32475c] dark:text-white dark:opacity-[60%] hover:text-[#4493f1] opacity-[70%] tablet:text-[22px] half:text-[18px] mobile:text-[16px]'}
                        >
                            {language === 'en' ? 'Associates' : 'Assosiate'}
                        </button>
                    </div>
                </div>
                <div className='flex flex-row px-[5vw]'>
                    <div className='grid tablet:grid-cols-2 mobile:grid-cols-1 med:grid-cols-2 half:grid-cols-1 grid-flow-row tablet:gap-4 mobile:gap-2 tablet:mt-[4vh] mobile:mt-[2vh]'>
                        {
                            filteredData.map((val, items) => {
                                if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return (
                                        <div className='flex flex-row tablet:mb-[2vh] mobile:mb-[1vh]' key={items}>
                                            {/* <a href={`/people/${val.id}`}> */}
                                            <a href={`/people/${val.name}`}>
                                                <div key={items} className='flex flex-row box1 caption-card cursor-pointer tablet:w-[43vw] half:w-[85vw] mobile:w-[85vw] med:w-[43vw] border-[0.1vw] border-[#d9d9d9] dark:border-[#31353A] bg-white dark:bg-transparent mr-[1vw] tablte:mt-[5vh]'>
                                                    <img src={val.image} loading='lazy' alt='people' className='img-fluid'></img>
                                                    <div className='pl-[1vw] flex flex-col justify-center w-[60%] tablet:py-[0vh] '>
                                                        <p className='font-mulish dark:text-white desktop:text-[28px] tablet:text-[28px] mobile:text-[16px] half:text-[25px] med:text-[16px] w-[95%] tablet:leading-[30px] mobile:leading-[25px] font-medium desktop:pb-[2vh] tablet:pb-[1vh] mobile:pb-[1vh]'>{val.name}</p>
                                                        <p className='font-mulish dark:text-white desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] tablet:leading-[30px] mobile:leading-[25px] font-normal desktop:pb-[2vh] tablet:pb-[1vh] mobile:pb-[1vh] opacity-[85%]'>{val.role}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                </div>
                <div className='flex justify-end items-center pr-[5vw]'>
                    <Pagination postsPerPage={postsPerPage} totalPosts={total} setCurrentPage={setCurrentPage} />
                </div>
            </section>
            <SubFooter />
        </>
    );
}

export default Partners;